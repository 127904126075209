<template>
  <section class="cabinet-of-curiosities">
    <h1>A cabinet of curiosities</h1>

    <PhotoSwipe folder="cabinet" :photo-number="7" />
  </section>
</template>

<script>
export default {
  name: 'CabinetOfCuriosities'
}
</script>

<style lang="scss">
.cabinet-of-curiosities {
}
</style>
